import { Suspense, useEffect, useRef, useState } from "react";
import Baslik from "../comps/Baslik"
import DalgaAlt from "../comps/DalgaAlt"
import Footer from "../comps/Footer"

import { useCookies } from 'react-cookie';
import SeritUrunlerimiz from "../comps/SeritUrunlerimiz";

import { AnimatePresence, motion } from 'framer-motion';


import titizAgroLogo_1 from '../assets/images/titizAgroLogo_1.png';
import { Card } from "react-bootstrap";
import Loading from "../comps/Loading";
import { Img } from "react-suspense-img";

import Seminis_Tomato_Altess from '../assets/images/product/Seminis_Tomato_Altess.webp';
import Seminis_Tomato_Eylem_2 from '../assets/images/product/Seminis_Tomato_Eylem_2.webp';
import Seminis_Cucumber_Gordion from '../assets/images/product/Seminis_Cucumber_Gordion.webp';
import Seminis_Cucumber_Tenedos from '../assets/images/product/Seminis_Cucumber_Tenedos.webp';
import Seminis_Cucumber_PS64 from '../assets/images/product/Seminis_Cucumber_PS64.webp';


function Urunlerimiz() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtUrunlerimiz, setTxtUrunlerimiz] = useState(null);
    const [txtTurkiye, setTxtTurkiye] = useState(null);
    const [txtMetin1, setTxtMetin1] = useState(null);
    const [txtMetin2, setTxtMetin2] = useState(null);
    const [txtMetin3, setTxtMetin3] = useState(null);
    const [txtMetin4, setTxtMetin4] = useState(null);
    const [txtMetin5, setTxtMetin5] = useState(null);
    const [txtMetin6, setTxtMetin6] = useState(null);
    const [txtMetin7, setTxtMetin7] = useState(null);

    const [txtDomates, setTxtDomates] = useState(null);
    const [txtHiyar, setTxtHiyar] = useState(null);

    const [txtAltessAltYazi, setTxtAltessAltYazi] = useState(null);
    const [txtEylemAltYazi, setTxtEylemAltYazi] = useState(null);
    const [txtPS64AltYazi, setTxtPS64AltYazi] = useState(null);
    const [txtTenedosAltYazi, setTxtTenedosAltYazi] = useState(null);
    const [txtGordionAltYazi, setTxtGordionAltYazi] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtTurkiye('Turkey');
            setTxtUrunlerimiz('products');
            setTxtMetin1('');
            setTxtMetin2('');
            setTxtMetin3('');
            setTxtMetin4('');
            setTxtMetin5('');
            setTxtMetin6('');
            setTxtMetin7('');

            setTxtDomates('Tomato');
            setTxtHiyar('Cucumber');

            setTxtAltessAltYazi('Hydroponic Agriculture Cluster Tomato');
            setTxtEylemAltYazi('Late Fall Greenhouse Tomato');
            setTxtPS64AltYazi('Cucumber');
            setTxtTenedosAltYazi('Early Spring, Late Fall Greenhouse Cucumber');
            setTxtGordionAltYazi('Greenhouse Cucumber');

        } else {
            setTxtTurkiye('Türkiye');
            setTxtUrunlerimiz('ürünlerimiz');
            setTxtMetin1('');
            setTxtMetin2('');
            setTxtMetin3('');
            setTxtMetin4('');
            setTxtMetin5('');
            setTxtMetin6('');
            setTxtMetin7('');

            setTxtDomates('Domates');
            setTxtHiyar('Hıyar');

            setTxtAltessAltYazi('Topraksız Tarım Salkım Domates');
            setTxtEylemAltYazi('Geç Güzlük Sera Domatesi');
            setTxtPS64AltYazi('Hıyar');
            setTxtTenedosAltYazi('Erken İlkbahar, Geç Güzlük Sera Hıyarı');
            setTxtGordionAltYazi('Yazlık Sera Hıyarı');
        }
        
    },[cookies.titizAgroWebLanguage])

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);


    return(
        <>
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                maxWidth: '100vw',
                minHeight: '100vh',
                overflowX: "hidden",
                overflowY: "hidden",
                backgroundColor: 'white',
            }}
        >
        <Baslik />
        <div
            style={{
                width: '100vw',
                height: '105px',
            }}
        >
        </div>
        <div
            className="fontGenel"
            style={{
                
            }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12"
                    >
                        <SeritUrunlerimiz />
                    </div>
                    <div
                        className="col-12"
                    >

                        <div
                            className='container-fluid mt-5 mb-5'
                            style={{
                                textAlign: 'justify',
                                width: '90vw',
                            }}
                        >
                            <div
                                className='row'
                            >
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{
                                            textAlign: 'justify'
                                        }}
                                    >
                                        <div
                                            className="container-fluid"
                                        >
                                            <div
                                                className="row"
                                            >
                                                <div
                                                    className="col-12"
                                                >
                                                    <span
                                                        style={{
                                                            color:'rgba(140, 0, 0, 1)',
                                                            // fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                    >
                                                        {txtDomates}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                            >
                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.vegetables.bayer.com/tr/tr-tr/urunlerimiz/domates/details.html/tomato_altess_turkey_deruiter_all_high-tech_glasshouse_all.html','_blank') }
                                                            style={{
                                                                height: '50vh',
                                                                maxHeight: '300px',
                                                                minHeight: '250px',
                                                            }}
                                                        >
                                                            <Card.Body
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'end',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <Suspense
                                                                                fallback={<Loading />}
                                                                            >
                                                                                <Img 
                                                                                    src={Seminis_Tomato_Altess}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        maxWidth: '250px',
                                                                                        height: '100%',
                                                                                        maxHeight: '200px',
                                                                                        borderRadius: '15px',
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                ALTESS
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(0, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '15px',
                                                                                }}
                                                                            >
                                                                                {txtAltessAltYazi}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>
                                                
                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.vegetables.bayer.com/tr/tr-tr/urunlerimiz/domates/details.html/tomato_eylem_turkey_seminis_all_greenhouse_all.html','_blank') }
                                                            style={{
                                                                height: '50vh',
                                                                maxHeight: '300px',
                                                                minHeight: '250px',
                                                            }}
                                                        >
                                                            <Card.Body
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'end',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <Suspense
                                                                                fallback={<Loading />}
                                                                            >
                                                                                <Img 
                                                                                    src={Seminis_Tomato_Eylem_2}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        maxWidth: '250px',
                                                                                        height: '100%',
                                                                                        maxHeight: '200px',
                                                                                        borderRadius: '15px',
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Eylem
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(0, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '15px',
                                                                                }}
                                                                            >
                                                                                {txtEylemAltYazi}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                            </div>

                                            <div
                                                className="row"
                                            >
                                                <div
                                                    className="col-12"
                                                >
                                                    <span
                                                        style={{
                                                            color:'rgba(140, 0, 0, 1)',
                                                            // fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                    >
                                                        {txtHiyar}
                                                    </span>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.vegetables.bayer.com/tr/tr-tr/urunlerimiz/hiyar/details.html/cucumber_ps_64_turkey_seminis_all_greenhouse_all.html','_blank') }
                                                            style={{
                                                                height: '50vh',
                                                                maxHeight: '300px',
                                                                minHeight: '250px',
                                                            }}
                                                        >
                                                            <Card.Body
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'end',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <Suspense
                                                                                fallback={<Loading />}
                                                                            >
                                                                                <Img 
                                                                                    // src='https://placehold.co/600x400'
                                                                                    src={Seminis_Cucumber_PS64}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        maxWidth: '250px',
                                                                                        height: '100%',
                                                                                        maxHeight: '200px',
                                                                                        borderRadius: '15px',
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                PS 64
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(0, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '15px',
                                                                                }}
                                                                            >
                                                                                {txtPS64AltYazi}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.vegetables.bayer.com/tr/tr-tr/urunlerimiz/hiyar/details.html/cucumber_tenedos_turkey_seminis_all_greenhouse_all.html','_blank') }
                                                            style={{
                                                                height: '50vh',
                                                                maxHeight: '300px',
                                                                minHeight: '250px',
                                                            }}
                                                        >
                                                            <Card.Body
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'end',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <Suspense
                                                                                fallback={<Loading />}
                                                                            >
                                                                                <Img 
                                                                                    // src='https://placehold.co/600x400'
                                                                                    src={Seminis_Cucumber_Tenedos}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        maxWidth: '250px',
                                                                                        height: '100%',
                                                                                        maxHeight: '200px',
                                                                                        borderRadius: '15px',
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                TENEDOS
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(0, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '15px',
                                                                                }}
                                                                            >
                                                                                {txtTenedosAltYazi}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.vegetables.bayer.com/tr/tr-tr/urunlerimiz/hiyar/details.html/cucumber_gordion_at_77_turkey_seminis_all_greenhouse_all.html','_blank') }
                                                            style={{
                                                                height: '50vh',
                                                                maxHeight: '300px',
                                                                minHeight: '250px',
                                                            }}
                                                        >
                                                            <Card.Body
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'end',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <Suspense
                                                                                fallback={<Loading />}
                                                                            >
                                                                                <Img 
                                                                                    // src='https://placehold.co/600x400'
                                                                                    src={Seminis_Cucumber_Gordion}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        maxWidth: '250px',
                                                                                        height: '100%',
                                                                                        maxHeight: '200px',
                                                                                        borderRadius: '15px',
                                                                                    }}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                GORDION
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(0, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '15px',
                                                                                }}
                                                                            >
                                                                                {txtGordionAltYazi}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                            </div>


                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}
        >
            <Footer />
        </div>
        
        <DalgaAlt />

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </div>
        </>
    )
} export default Urunlerimiz