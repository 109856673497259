import { Suspense, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap"

import { useCookies } from 'react-cookie';

import { motion } from "framer-motion";
import { Img } from "react-suspense-img";

import Growtech_foto_1 from '../assets/images/growtech2024/Growtech_foto_1.webp';
import Growtech_foto_2 from '../assets/images/growtech2024/Growtech_foto_2.webp';
import Growtech_foto_3 from '../assets/images/growtech2024/Growtech_foto_3.webp';
import Growtech_foto_4 from '../assets/images/growtech2024/Growtech_foto_4.webp';
import Growtech_foto_5 from '../assets/images/growtech2024/Growtech_foto_5.webp';


function Duyuru1() {

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtDuyuruBaslik, setTxtDuyuruBaslik] = useState(null);
    const [txtDuyuruIcerik1, setTxtDuyuruIcerik1] = useState(null);
    const [txtDuyuruIcerik2, setTxtDuyuruIcerik2] = useState(null);
    const [txtDuyuruIcerik3, setTxtDuyuruIcerik3] = useState(null);
    const [txtDetay, setTxtDetay] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtDuyuruBaslik('GROWTECH 2024 International Agriculture Fair');
            setTxtDetay('Detail');
            setTxtDuyuruIcerik1('On November 20 - 23, 2024, we had the opportunity to meet with our visitors by taking our place at Growtech International Agricultural Fair.');
            setTxtDuyuruIcerik2('Our stand, where we introduced our innovations and solutions in the agricultural sector, was met with great interest. We interacted one-on-one with our visitors, answered their questions and shared detailed information about our products. Thank you to everyone who was with us. We will continue to contribute to the future of agriculture and offer innovations in line with the needs of the sector.');
            setTxtDuyuruIcerik3('Hope to see you at the next event!');

        } else {
            setTxtDuyuruBaslik('GROWTECH 2024 Uluslararası Tarım Fuarı ');
            setTxtDetay('Detay');
            setTxtDuyuruIcerik1('20 - 23 Kasım 2024 tarihinde Growtech Uluslararası Tarım Fuarı’nda yerimizi alarak ziyaretçilerimizle buluşma fırsatı bulduk.');
            setTxtDuyuruIcerik2('Tarım sektöründeki yeniliklerimizi ve çözümlerimizi tanıttığımız standımız, yoğun ilgiyle karşılandı. Ziyaretçilerimizle birebir etkileşimde bulunarak sorularını yanıtladık ve ürünlerimiz hakkında detaylı bilgi paylaştık. Bizimle olan herkese teşekkür ederiz. Tarımın geleceğine katkı sağlamaya ve sektörün ihtiyaçlarına uygun yenilikler sunmaya devam edeceğiz.');
            setTxtDuyuruIcerik3('Bir sonraki etkinlikte görüşmek dileğiyle!');

        }
        
    },[cookies.titizAgroWebLanguage])


    return(
        <>
        <motion.div
            initial={{ opacity: 0, y: -10, scale: 1 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            className="m-3"
        >
            <Card>
                <Card.Body>
                    <div
                        className="container-fluid"
                    >
                        <div
                            className="row"
                        >
                            <div
                                className="col-12 yatayDikeyOrtala"
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <span>
                                    {txtDuyuruBaslik}
                                </span>
                            </div>
                            <div
                                className="col-12"
                            >
                                <hr />
                            </div>
                            <div
                                className="col-12"
                            >
                                <div
                                    className="container-fluid"
                                >
                                    <div
                                        className="row"
                                    >
                                        {/* <div
                                            className="col-md-12 col-lg-4 yatayDikeyOrtala"
                                        >
                                            <div>
                                                <img 
                                                    src="https://placehold.co/600x400"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>
                                        </div> */}
                                        <div
                                            className="col-md-12 col-lg-12 yatayDikeyOrtala"
                                        >
                                            <div
                                                className="container-fluid"
                                            >
                                                <div
                                                    className="row"
                                                >
                                                    <div
                                                        className="col-12"
                                                    >
                                                        <p>
                                                             {txtDuyuruIcerik1}
                                                        </p>
                                                        <p>
                                                             {txtDuyuruIcerik2}
                                                        </p>
                                                        <p>
                                                             {txtDuyuruIcerik3}
                                                        </p>
                                                    </div>
                                                    {/* <div
                                                        className="col-12 yatayDikeyOrtala"
                                                    >
                                                        <Button
                                                            variant="outline-success"
                                                        >
                                                            {txtDetay}
                                                        </Button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12"
                                        >
                                            <div
                                                className="container-fluid"
                                            >
                                                <div
                                                    className="row"
                                                >
                                                    <div
                                                        className="col-md-12 col-lg-3 yatayDikeyOrtala"
                                                    >
                                                        <Suspense
                                                            fallback={<span>yükleniyor...</span>}
                                                        >
                                                            <Img 
                                                                src={Growtech_foto_1}
                                                                alt="Antalya Tarım Growtech 2024 resim 1"
                                                                style={{
                                                                    // width: '100%',
                                                                    maxWidth: '100%',
                                                                    height: '50vh',
                                                                    maxHeight: '500px',
                                                                    // height: '10vh',
                                                                    // maxHeight: '700px',
                                                                    borderRadius: '25px',
                                                                    padding: '10px',
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </div>
                                                    <div
                                                        className="col-md-12 col-lg-3 yatayDikeyOrtala"
                                                    >
                                                        <Suspense
                                                            fallback={<span>yükleniyor...</span>}
                                                        >
                                                            <Img 
                                                                src={Growtech_foto_5}
                                                                alt="Antalya Tarım Growtech 2024 resim 2"
                                                                style={{
                                                                    // width: '100%',
                                                                    maxWidth: '100%',
                                                                    height: '50vh',
                                                                    maxHeight: '500px',
                                                                    // height: '10vh',
                                                                    // maxHeight: '700px',
                                                                    borderRadius: '25px',
                                                                    padding: '10px',
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </div>
                                                    <div
                                                        className="col-md-12 col-lg-3 yatayDikeyOrtala"
                                                    >
                                                        <Suspense
                                                            fallback={<span>yükleniyor...</span>}
                                                        >
                                                            <Img 
                                                                src={Growtech_foto_3}
                                                                alt="Antalya Tarım Growtech 2024 resim 3"
                                                                style={{
                                                                    // width: '100%',
                                                                    maxWidth: '100%',
                                                                    height: '50vh',
                                                                    maxHeight: '500px',
                                                                    // height: '10vh',
                                                                    // maxHeight: '700px',
                                                                    borderRadius: '25px',
                                                                    padding: '10px',
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </div>
                                                    <div
                                                        className="col-md-12 col-lg-3 yatayDikeyOrtala"
                                                    >
                                                        <Suspense
                                                            fallback={<span>yükleniyor...</span>}
                                                        >
                                                            <Img 
                                                                src={Growtech_foto_4}
                                                                alt="Antalya Tarım Growtech 2024 resim 4"
                                                                style={{
                                                                    // width: '100%',
                                                                    maxWidth: '100%',
                                                                    height: '50vh',
                                                                    maxHeight: '500px',
                                                                    // height: '10vh',
                                                                    // maxHeight: '700px',
                                                                    borderRadius: '25px',
                                                                    padding: '10px',
                                                                }}
                                                            />
                                                        </Suspense>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </motion.div>
        </>
    )
} export default Duyuru1